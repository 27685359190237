import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './DropdownMenu.css';
import { MenuData } from '../../types/MenuData';
import { Category } from '../../types/Category';
import { getCategoriesByMenuData } from '../../api/requests/categorieRequests';
import { getMenuDataByNavItemId } from '../../api/requests/menuDataRequests';
import { getNavItemById } from '../../api/requests/navItemRequests';

interface DropdownProps {
  navItemId: string;
  closeDropdown: () => void;
}

const formatLabelForUrl = (label: string) => {
  return label
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/&/g, 'and')
    .replace(/@/g, '%40')
    //.replace(/[^\w-]/g, ''); // Enlever les caractères spéciaux sauf les tirets
};

const Dropdown: React.FC<DropdownProps> = ({ navItemId, closeDropdown }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [menuData, setMenuData] = useState<MenuData[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const location = useLocation();
  const [navItemLabel, setNavItemLabel] = useState('');

  const fetchNavItemLabel = async () => {
    try {
      const data = await getNavItemById(navItemId);
      setNavItemLabel(formatLabelForUrl(data.label));
    } catch (error) {
      console.error("Error fetching NavItem label:", error);
    }
  };

  const fetchMenuData = async () => {
    if (!navItemId) return;
    try {
      const data = await getMenuDataByNavItemId(navItemId);
      setMenuData(data);
      if (data.length > 0) {
        fetchCategoriesForMenuData(data[0].id);
      }
    } catch (error) {
      console.error("Error fetching MenuData:", error);
    }
  };

  const fetchCategoriesForMenuData = async (menuId: string) => {
    try {
      const data = await getCategoriesByMenuData(menuId);
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    if (navItemId) {
      fetchNavItemLabel();
      fetchMenuData();
    }
  }, [navItemId]);

  const handleMenuClick = (index: number, menuId: string) => {
    setActiveTab(index);
    fetchCategoriesForMenuData(menuId);
  };
  const generateUrl = (categoryLabel: string) => {
    if (!navItemLabel || !menuData[activeTab]?.label || !categoryLabel) {
      return '#';
    }
    return `/category/${navItemLabel}/${menuData[activeTab].label}/${categoryLabel}`;
  };



  const handleLinkClick = () => {
    closeDropdown();
  };

  const chunkCategories = (categories: Category[], chunkSize: number) => {
    const result = [];
    for (let i = 0; i < categories.length; i += chunkSize) {
      result.push(categories.slice(i, i + chunkSize));
    }
    return result;
  };

  const categoriesGrouped = chunkCategories(categories, 4);

  return (
    <div className="dropdown">
      <div className="tabs">
        {menuData.map((tab, index) => (
          <button
            key={tab.label}
            className={`tab-button ${activeTab === index ? 'active-tab' : ''}`}
            onClick={() => handleMenuClick(index, tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <hr className="hr-separator" />

      <div className="categories-grid">
        {categoriesGrouped.map((categoryGroup, groupIndex) => (
          <div key={groupIndex} className="category-column">
            {categoryGroup.map((category) => (
              <NavLink
                key={category.id}
                to={navItemLabel ? generateUrl(category.label) : '#'}
                className={`category-link ${location.pathname.includes(formatLabelForUrl(category.label)) ? 'active' : ''}`}
                onClick={handleLinkClick}
              >
                {category.label}
              </NavLink>
            ))}
          </div>
        ))}
      </div>

      <NavLink
        to={
          navItemLabel && menuData[activeTab]?.label
            ? `/department/${formatLabelForUrl(navItemLabel)}/${formatLabelForUrl(menuData[activeTab].label)}`
            : "#"
        }
        className="see-all-link"
      >
        Voir tout {menuData[activeTab]?.label}
      </NavLink>



    </div>
  );
};

export default Dropdown;