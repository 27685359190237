import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DepartmentsSection from '../Components/DepartmentsSection/DepartmentsSection';
import ShopByMenu from '../Components/HomePage/MainContent/ShopBy/ShopByMenu';
import { getNavItemsByType } from '../api/requests/navItemRequests';

const DepartmentPage: React.FC = () => {
  const { navItemLabel } = useParams<{ navItemLabel: string }>(); // Récupérer le label du navItem
  const [navItems, setNavItems] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [activeItemId, setActiveItemId] = useState<string | null>(null);

  // Fonction de décodage du label depuis l'URL
  const decodeLabelFromUrl = (encodedLabel: string) => {
    return encodedLabel
      .replace(/-/g, ' ')              // Remplacer les tirets par des espaces
      .replace(/and/g, '&')            // Remplacer 'and' par '&'
      .replace(/%40/g, '@')            // Remplacer '%40' par '@'
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Mettre chaque mot avec une majuscule
  };

  useEffect(() => {
    const fetchNavItems = async () => {
      try {
        const items = await getNavItemsByType('HOME'); // Remplacez "HOME" par le type approprié si nécessaire
        setNavItems(items);
        console.log('NavItems fetched:', items); // Vérifier les NavItems récupérés
      } catch (error) {
        console.error('Erreur lors de la récupération des NavItems:', error);
      }
    };

    fetchNavItems();
  }, []);

  // Rechercher l'ID correspondant au label récupéré
  useEffect(() => {
    if (navItemLabel) {
      console.log('Searching for NavItem with label:', navItemLabel); // Vérifier le label récupéré
  
      // Décoder le label récupéré depuis l'URL
      const decodedLabel = decodeLabelFromUrl(navItemLabel);
      console.log('Decoded label:', decodedLabel); // Vérifier le label décodé
  
      // Log de tous les NavItems pour voir leur contenu
      console.log('All NavItems:', navItems);
  
      const foundNavItem = navItems.find((item) => {
        // Comparer les labels de manière insensible à la casse
        console.log('Checking NavItem label:', item.label); // Vérifier le label de l'élément
        return item.label.toLowerCase() === decodedLabel.toLowerCase(); // Comparer en ignorant la casse
      });
  
      if (foundNavItem) {
        console.log('Found NavItem:', foundNavItem); // Vérifier le NavItem trouvé
        setActiveItemId(foundNavItem.id);
      } else {
        console.log('NavItem not found for label:', decodedLabel); // Vérifier si le NavItem est trouvé ou non
      }
    }
  }, [navItemLabel, navItems]);
  
  

  const handleClick = (id: string) => {
    setActiveItemId(id);
  };

  return (
    <div className="department-page">
      <ShopByMenu
        items={items}
        activeItemId={activeItemId}
        onClick={handleClick}
      />
      {navItemLabel && activeItemId && <DepartmentsSection navItemId={activeItemId} />}
    </div>
  );
};

export default DepartmentPage;
