import { MenuData } from "../../types/MenuData";
import HTTP_CLIENT from "../client";

const API_ENDPOINT = "menu_data";

// Récupérer tous les MenuData
export const getMenuData = async (): Promise<MenuData[]> => {
  try {
    const response = await HTTP_CLIENT.get<{ data: MenuData[] }>(API_ENDPOINT);
    return response.data.data; // Accéder à la propriété data de la réponse
  } catch (error) {
    console.error("Erreur lors de la récupération des MenuData:", error);
    throw error;
  }
};

// Récupérer un MenuData par son ID
export const getMenuDataById = async (id: string): Promise<MenuData> => {
  if (!id) {
    throw new Error("ID is required");
  }

  try {
    const response = await HTTP_CLIENT.get<{ data: MenuData }>(`${API_ENDPOINT}/${id}`);

    // Vérifier que la réponse a bien la structure attendue
    if (!response.data || !response.data.data) {
      throw new Error("Données du menu non valides");
    }

    return response.data.data; // Accéder à la propriété data de la réponse
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error("Erreur lors de la récupération du MenuData:", error.message);
      throw new Error(`Erreur API: ${error.message}`);
    } else {
      console.error("Erreur inconnue lors de la récupération du MenuData");
      throw new Error("Erreur inconnue");
    }
  }
  
};// Récupérer un MenuData par son label
export const getMenuDataIdByLabel = async (label: string): Promise<string> => {
  try {
    const response = await HTTP_CLIENT.get<{ data: string; message: string; success: boolean }>(
      `${API_ENDPOINT}/label/${label}`
    );

    if (response.data.success) {
      return response.data.data; // Retourne l'ID du NavItem (UUID)
    } else {
      throw new Error(response.data.message); // Si le succès est faux, on lance une erreur avec le message
    }
  } catch (error) {
    console.error("Erreur lors de la récupération de l'ID du NavItem par label:", error);
    throw error;
  }
};


// Récupérer les MenuData par NavItem ID
export const getMenuDataByNavItemId = async (navItemId: string): Promise<MenuData[]> => {
  if (!navItemId) {
    throw new Error("NavItem ID is required");
  }
  try {
    const response = await HTTP_CLIENT.get<{ data: MenuData[] }>(`${API_ENDPOINT}/byNavItem/${navItemId}`);
    return response.data.data; // Accéder à la propriété data de la réponse
  } catch (error) {
    console.error("Erreur lors de la récupération des MenuData pour le NavItem:", error);
    throw error;
  }
};

// Créer un nouveau MenuData
export const createMenuData = async (formData: FormData): Promise<MenuData> => {
  try {
    const response = await HTTP_CLIENT.post<{ data: MenuData }>(API_ENDPOINT, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.data; // Accéder à la propriété data de la réponse
  } catch (error) {
    console.error("Erreur lors de la création du MenuData:", error);
    throw error;
  }
};

// Mettre à jour un MenuData existant
export const updateMenuData = async (id: string, formData: FormData): Promise<MenuData> => {
  try {
    const response = await HTTP_CLIENT.put<{ data: MenuData }>(`${API_ENDPOINT}/${id}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.data; // Accéder à la propriété data de la réponse
  } catch (error) {
    console.error("Erreur lors de la mise à jour du MenuData:", error);
    throw error;
  }
};

// Supprimer un MenuData
export const deleteMenuData = async (id: string): Promise<void> => {
  try {
    await HTTP_CLIENT.delete(`${API_ENDPOINT}/${id}`);
  } catch (error) {
    console.error("Erreur lors de la suppression du MenuData:", error);
    throw error;
  }
};
