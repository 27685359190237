import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import arrow icons
import './PopularProduct.css';
import QuantitySelector from '../../../Categories/QuantitySelector/QuantitySelector';
import { useCart } from '../../CartContext';
import { Link } from 'react-router-dom';
import { getProductsByProductTypeId, getShopByForProduct } from '../../../../api/requests/ProductRequests';

import { getProductTypes } from '../../../../api/requests/ProductTypeRequests';
import { Product as ProductType } from '../../../../types/Products';  // Corrected import
import Product from '../../../Categories/Product';

interface ProductSectionProps {
  productType: { id: string; title: string; description: string; image: string };
  products: ProductType[];
  currentIndex: number;
  productsPerRow: number;
  shopByLabels: { [key: string]: { label: string; color: string }[] };
  quantities: { [key: string]: number };
  handlePrev: () => void;
  handleNext: () => void;
  onQuantityChange: (productName: string, quantity: number) => void;
}

const ProductSection: React.FC<ProductSectionProps> = ({
  productType,
  products,
  currentIndex,
  productsPerRow,
  shopByLabels,
  quantities,
  handlePrev,
  handleNext,
  onQuantityChange,
}) => {
  const displayedProducts = products.slice(currentIndex, currentIndex + productsPerRow);

  return (
    <div className="popular-products-container">
      <div className="content-section">
      <div className="text-column">
          <h2>{productType.title}</h2>
          

          <div className="navigation-and-description">
            <p>{productType.description}</p>
            
            {products.length > 0 && (
              <div className="product-navigation">
                <button className="prev-button" onClick={handlePrev} disabled={currentIndex === 0}>
                  <FaChevronLeft />
                </button>
                <button
                  className="next-button"
                  onClick={handleNext}
                  disabled={currentIndex + productsPerRow >= products.length}
                >
                  <FaChevronRight />
                </button>
              </div>
            )}
            <Link to={`/products/${productType.id}`} className="view-button mobile-only">View All</Link>
          </div>
          <div className="product-list1">
            {displayedProducts.map((product) => (
              <Product
                key={product.id}
                product={[product]}  // Pass product as an array
                totalProductCount={displayedProducts.length}

              />

            ))}
          </div>

        </div>
      </div>
      <div className="image-column">
        <img src={productType.image} alt="Image des produits populaires" className="product-Image" />
      </div>
    </div>
  );
};

const ProductsParType: React.FC<{ products: ProductType[] }> = ({ products }) => {
  const [productTypes, setProductTypes] = useState<{ [key: string]: { id: string; title: string, description: string, image: string } }>({});
  const [productsByType, setProductsByType] = useState<{ [key: string]: ProductType[] }>({});
  const [shopByLabels, setShopByLabels] = useState<{ [key: string]: { label: string; color: string }[] }>({});
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});

  const { cartItems, updateQuantity, addToCart, removeFromCart } = useCart();
  const [currentIndex, setCurrentIndex] = useState<{ [key: string]: number }>({});
  const [notification, setNotification] = useState<string | null>(null);

  const [productsPerRow, setProductsPerRow] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setProductsPerRow(2); // 1 product per row on small screens
      } else if (window.innerWidth >= 992 && window.innerWidth <= 1140) {
        setProductsPerRow(2); // 2 products per row on medium screens
      } else if (window.innerWidth > 1140 && window.innerWidth <= 1493) {
        setProductsPerRow(3); // 3 products per row on larger screens
      } else {
        setProductsPerRow(4); // 4 products per row on very large screens
      }
    };

    handleResize(); // Initialize window size on load
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Navigation vers les produits précédents
  const handlePrev = (productTypeId: string) => {
    setCurrentIndex((prevIndex) => ({
      ...prevIndex,
      [productTypeId]: Math.max(0, (prevIndex[productTypeId] || 0) - productsPerRow),
    }));
  };

  // Navigation vers les produits suivants
  const handleNext = (productTypeId: string) => {
    setCurrentIndex((prevIndex) => {
      const maxIndex = Math.max(0, (productsByType[productTypeId]?.length || 0) - productsPerRow);
      return {
        ...prevIndex,
        [productTypeId]: Math.min(maxIndex, (prevIndex[productTypeId] || 0) + productsPerRow),
      };
    });
  };

  // Fetch product types
  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const types = await getProductTypes();
        const typesMap = types.reduce<{ [key: string]: { id: string; title: string, description: string, image: string } }>((acc, type) => {
          acc[type.id] = { id: type.id, title: type.title, description: type.description, image: type.image };
          return acc;
        }, {});
        setProductTypes(typesMap);
      } catch (error) {
        console.error("Error fetching product types:", error);
      }
    };
    fetchProductTypes();
  }, []);

  // Fetch products by type
  useEffect(() => {
    const fetchProductsByType = async () => {
      if (Object.keys(productTypes).length === 0) return;
      const productData: { [key: string]: ProductType[] } = {};
      for (const productTypeId of Object.keys(productTypes)) {
        const products = await getProductsByProductTypeId(productTypeId);
        productData[productTypeId] = products;
      }
      setProductsByType(productData);
    };
    fetchProductsByType();
  }, [productTypes]);

  // Fetch shopBy labels
  useEffect(() => {
    const fetchShopByLabels = async () => {
      try {
        const labels: { [key: string]: { label: string; color: string }[] } = {};
        for (const productId of Object.keys(productsByType).flatMap(typeId => productsByType[typeId].map(p => p.id))) {
          const shopBys = await getShopByForProduct(productId);
          labels[productId] = shopBys.map((shopBy: any) => ({
            label: shopBy.label,
            color: shopBy.color,
          }));
        }
        setShopByLabels(labels);
      } catch (error) {
        console.error("Error fetching ShopBy labels:", error);
      }
    };
    if (Object.keys(productsByType).length > 0) {
      fetchShopByLabels();
    }
  }, [productsByType]);

  return (
    <div>
      {notification && <div className="notification"><p>{notification}</p></div>}
      {Object.keys(productTypes).map((typeId) => {
        const productType = productTypes[typeId];
        const products = productsByType[typeId] || [];
        if (products.length === 0) return null;

        function handleQuantityChange(productName: string, quantity: number): void {
          throw new Error('Function not implemented.');
        }

        return (
          <ProductSection
            key={typeId}
            productType={productTypes[typeId]}
            products={productsByType[typeId] || []}
            currentIndex={currentIndex[typeId] || 0}
            productsPerRow={productsPerRow}
            shopByLabels={shopByLabels}
            quantities={quantities}
            handlePrev={() => handlePrev(typeId)}
            handleNext={() => handleNext(typeId)} 
            onQuantityChange={handleQuantityChange}
                    />
        );
      })}
    </div>
  );
};

export default ProductsParType;
