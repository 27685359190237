import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getCategoriesByMenuData } from '../api/requests/categorieRequests';
import { getNavItemIdByLabel } from '../api/requests/navItemRequests';
import { Category } from '../types/Category';
import './DepartmentsPage2.css';
import { getMenuDataIdByLabel } from '../api/requests/menuDataRequests';

const DepartmentsPage2: React.FC = () => {
  const { navItemLabel, menuDataLabel } = useParams<{ navItemLabel: string; menuDataLabel: string }>();

  const [navItemId, setNavItemId] = useState<string | null>(null);
  const [menuDataId, setMenuDataId] = useState<string | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [error, setError] = useState<string>('');

  /*const formatLabelForUrl = (label: string) => {
    return label
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/&/g, 'and')
      .replace(/@/g, '%40')
      
  };*/

  // Formatage des labels pour affichage
  const formatLabel = (label: string) => {
    return label
      .replace(/-/g, ' ') // Remplacer les tirets par des espaces
      .replace(/%40/g, '&')
      .replace(/and/g, '@')
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Mettre chaque mot avec une majuscule
  };

  useEffect(() => {
    const fetchIds = async () => {
      try {
        if (navItemLabel) {
          // Formatage pour l'URL
          //const formattedNavItemLabel = formatLabelForUrl(navItemLabel);
          const id = await getNavItemIdByLabel(navItemLabel);
          if (!id) {
            throw new Error('NavItem not found');
          }
          setNavItemId(id);
        }
    
        if (menuDataLabel) {
          // Formatage pour l'URL et pour l'affichage
          //const formattedMenuDataLabel = formatLabelForUrl(menuDataLabel);
          const formattedMenuDataForDisplay = formatLabel(menuDataLabel); // Appliquer la mise en majuscule pour l'affichage
          const id = await getMenuDataIdByLabel(formattedMenuDataForDisplay);
          if (!id) {
            throw new Error('MenuData not found');
          }
          setMenuDataId(id);
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error("Erreur lors de la récupération des IDs:", error.message);
          setError(`Erreur lors du chargement des données: ${error.message}`);
        } else {
          console.error("Erreur inconnue:", error);
          setError("Erreur inconnue lors du chargement des données");
        }
      }
    };
    
    fetchIds();
  }, [navItemLabel, menuDataLabel]);
    
    

  useEffect(() => {
    const fetchCategories = async () => {
      if (menuDataId) {
        try {
          const data = await getCategoriesByMenuData(menuDataId);
          setCategories(data);
        } catch (error) {
          console.error("Erreur lors de la récupération des catégories:", error);
          setError('Impossible de charger les catégories');
        }
      }
    };

    fetchCategories();
  }, [menuDataId]);

  if (!navItemId || !menuDataId) {
    return <div className="loading">Chargement...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="category-page">
      <nav aria-label="breadcrumb" className="breadcrumbs">
        <ol className="bread">
          <li className="breadcrumb-item">
            <Link to={`/department/${navItemLabel!.toLowerCase().replace(/ /g, '-').replace(/&/g, 'and')}`}>
              {navItemLabel}
            </Link>

          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <span>{menuDataLabel}</span>
          </li>
        </ol>
      </nav>

      <div className="categories-grid">
        {categories.map((category) => (
          <Link key={category.id} to={`/category/${navItemLabel}/${menuDataLabel}/${category.label}`}>
            {category.label}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default DepartmentsPage2;
