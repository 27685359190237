import React, { useState, useEffect } from 'react';
import SignInModal from '../Account/SignInModal/SignInModal';
import { itemsToSearch } from './itemsToSearch';
import { SearchBarProps, AccountSectionProps, CartButtonProps } from './interfaces';
import './TopLine.css';
import CreateAccount from '../Account/CreateAccount/CreateAccount';
import CartModal from '../../Cart';
import { useCart } from '../../CartContext';
import ModalWindow from '../Delivery/Delivery';
import { Typography } from 'antd';


interface TopLineProps {
  location: string;
  onLocationChange: (newLocation: string) => void;
}
export interface Location {
  latitude: string;
  longitude: string;
  address: string;
}

const TopLine: React.FC<TopLineProps> = ({ location, onLocationChange }) => {

  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false);
  const [isCreateAccountModalOpen, setIsCreateAccountModalOpen] = useState<boolean>(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState<boolean>(false);
  const { cartItems } = useCart();
  const [searchValue, setSearchValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 992);

  const openAddressModal = () => {
    console.log("Opening Address Modal...");
    setIsAddressModalOpen(true);
  };
  
  const closeAddressModal = () => setIsAddressModalOpen(false);
  const [selectedAddress, setSelectedAddress] = useState<string>("");

  const handleLocationSelect = (location: Location) => {
    setSelectedAddress(location.address); // Access 'address' from Location
    closeAddressModal(); // Close the modal after selecting the address
  };



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    setSearchResults(value
      ? itemsToSearch.filter(item => item.toLowerCase().includes(value.toLowerCase()))
      : []
    );
  };

  const clearSearch = () => {
    setSearchValue('');
    setSearchResults([]);
  };

  const toggleLocation = () => {
    const newLocation = location === 'Home' ? 'Residential' : 'Home';
    onLocationChange(newLocation);
  };

  const handleSearchFocus = () => setIsFocused(true);
  const handleSearchBlur = () => {
    if (!searchValue) setIsFocused(false);
  };

  // Mobile view
  if (isMobile) {
    return null; // Hide TopLine on mobile devices, consider creating a mobile layout
  }

  return (
    <div className="TopLine_layout_start">
      <div className="flex items-center justify-between w-full">
        <Logo />

        <div className="border-l border-gray-300 h-8 mx-4" />
        <DeliverySection
          onOpenAddressModal={openAddressModal}
          selectedAddress={selectedAddress} // Pass the address here
        />
        <SearchBar
          searchValue={searchValue}
          onChange={handleSearchChange}
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
          clearSearch={clearSearch}
          isFocused={isFocused}
        />
        <LocationSwitcher location={location} toggleLocation={toggleLocation} />
        <AccountSection
          isSignedIn={false}
          onSignInClick={() => {
            setIsSignInModalOpen(true);
          }}
          onCreateAccountClick={() => setIsCreateAccountModalOpen(true)}
        />
        <CartButton
          cartItems={cartItems}
          onClick={() => setIsCartModalOpen(true)}
        />
      </div>

      {/* Modals */}
      <SignInModal isOpen={isSignInModalOpen} onClose={() => setIsSignInModalOpen(false)} />
      <CreateAccount isOpen={isCreateAccountModalOpen} onClose={() => setIsCreateAccountModalOpen(false)} />
      <CartModal isOpen={isCartModalOpen} onClose={() => setIsCartModalOpen(false)} />
      <ModalWindow
        isOpen={isAddressModalOpen}
        onClose={closeAddressModal}
        onLocationSelected={handleLocationSelect}
      />




      {searchResults.length > 0 && (
        <div className="search-results">
          {searchResults.map((result, index) => (
            <div key={result} className="search-result-item">
              {result}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const Logo: React.FC = () => (
  <div data-testid="logo" className="TopLine_logo_wrapper" role="group" tabIndex={0}>
    <a className="TopLine_logo" href="/">
      <img src="/assets/SVG/logo2.png" alt="Freshdirect logo" className="TopLine_logo_image" />
    </a>
  </div>
);

// Delivery Section Component
export const DeliverySection: React.FC<{ onOpenAddressModal: () => void, selectedAddress: string }> = ({ onOpenAddressModal, selectedAddress }) => (
  <div className="TopLine_delivery" data-testid="delivery-section">
    <p className="TopLine_delivery_title">Delivery</p>
    <button
      className="Button_button Button_small Button_underline_light Address_link"
      onClick={onOpenAddressModal}
    >
      {selectedAddress || "Get your address"}
    </button>
  </div>
);
// LocationSwitcher Component
export const LocationSwitcher: React.FC<{ location: string; toggleLocation: () => void }> = ({ location, toggleLocation }) => (
  <div className="button-content" onClick={toggleLocation} role="button" tabIndex={0}>
    <span className="text-sm font-bold mr-2">Go to</span>
    <div>
      <span className="text-sm font-normal">{location}</span>
      <span className="ml-2">›</span>
    </div>
  </div>
);

// SearchBar Component
export const SearchBar: React.FC<SearchBarProps> = ({
  searchValue, onChange, onFocus, onBlur, clearSearch, isFocused
}) => (
  <form className="searchbar-form">
    <div className="searchbar-input-wrapper">
      <input
        type="search"
        value={searchValue}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder="Search"
        className={`searchbar-input ${isFocused ? 'focused' : ''}`}
      />
    </div>
  </form>
);

// Account Section Component
export const AccountSection: React.FC<AccountSectionProps & { onCreateAccountClick: () => void, onSignInClick: () => void }> = ({ isSignedIn, onSignInClick, onCreateAccountClick }) => (
  <div className="flex account-section">
    <p className="text-sm font-bold account-title">Account</p>
    <div className="ml-2">
      {!isSignedIn ? (
        <>
          <button className="account-link" onClick={onSignInClick}>Sign in</button>
          <span className="text-sm mx-0.5 or-text">or</span>
          <button className="account-link" onClick={onCreateAccountClick}>Create an Account</button>
        </>
      ) : (
        <p className="text-sm font-normal">Welcome Back!</p>
      )}
    </div>
  </div>
);

// CartButton Component
export const CartButton: React.FC<{ cartItems: { quantity: number }[]; onClick: () => void }> = ({ cartItems, onClick }) => {
  const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);

  return (
    <button
      className="cart-button relative"
      aria-label={`Your Bag with ${totalQuantity} items`}
      onClick={onClick}
    >
      <img src="/assets/SVG/panier.png" alt="Cart Icon" className="cart-icon" />
      {totalQuantity > 0 && (
        <span className="Cart_cart_counter__3IczK">
          {totalQuantity}
        </span>
      )}
    </button>
  );
};

export default TopLine;
