import React from 'react';
import PropTypes from 'prop-types';
import './ModalContent.css'; // Importer les styles externes

interface ModalContentProps {
    activeTab: 'description' | 'nutrition';
    onTabChange: (tab: 'description' | 'nutrition') => void;
    content: string | React.ReactNode;
    onClose: () => void; // Add onClose function to handle closing the modal
  }
  
  const ModalContent: React.FC<ModalContentProps> = ({ activeTab, onTabChange, content, onClose }) => {
  return (
    <div className="modal-container">
      {/* Bouton de fermeture */}
      <button className="modal-close-button" onClick={onClose}>
        ×
      </button>

      {/* Barre des onglets */}
      <div className="modal-tab-bar">
        <button
          className={`modal-tab ${activeTab === 'description' ? 'active-tab' : ''}`}
          onClick={() => onTabChange('description')}
        >
          Description
        </button>
        <button
          className={`modal-tab ${activeTab === 'nutrition' ? 'active-tab' : ''}`}
          onClick={() => onTabChange('nutrition')}
        >
          Nutrition
        </button>
      </div>

      {/* Contenu */}
      <div className="modal-content">{content}</div>
    </div>
  );
};



export default ModalContent;
