import React, { useEffect, useState } from 'react';
import './AllProduct.css';
import { useCart } from '../../CartContext';
import { Product as ProductType } from '../../../../types/Products';
import { getProductsByProductTypeId } from '../../../../api/requests/ProductRequests';
import { useParams } from 'react-router-dom';
import { getProductTypeById } from '../../../../api/requests/ProductTypeRequests';
import Product from '../../../Categories/Product';

const AllProductsPage: React.FC = () => {
  const [products, setProducts] = useState<ProductType[]>([]); // Liste des produits
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({}); // Quantités
  const { addToCart, updateQuantity, removeFromCart } = useCart(); // Gestion du panier
  const { productTypeId } = useParams<{ productTypeId: string }>(); // Récupération du paramètre `productTypeId`
  const [productType, setProductType] = useState<any>(null); // État pour le type de produit
  const [shopByLabels, setShopByLabels] = useState<{ [key: string]: { label: string; color: string }[] }>({});
 
  const handleQuantityChange = (productName: string, quantity: number) => {
    const product = products.find((p) => p.name === productName);
    if (product) {
      if (quantity > 0) {
        updateQuantity(productName, quantity);
      } else {
        removeFromCart(productName);
      }
    }
  };

  useEffect(() => {
    if (productTypeId) {
      const fetchProductData = async () => {
        try {
          const fetchedProductType = await getProductTypeById(productTypeId);
          setProductType(fetchedProductType);

          const fetchedProducts = await getProductsByProductTypeId(productTypeId);
          setProducts(fetchedProducts);
        } catch (error) {
          console.error("Erreur lors de la récupération des produits et du productType :", error);
        }
      };

      fetchProductData();
    }
  }, [productTypeId]);

  return (
    <div className="all-products-page">
      {productType && (
        <div className="product-type-section">
          <div className="all-products-image-text-container">
            <div className="all-products-image-column">
              <img src={productType.image} alt={productType.title} className="all-products-image" />
            </div>
            <div className="all-products-text-column">
              <h2>{productType.title}</h2>
              <p>{productType.description}</p>
            </div>
          </div>

          <div className="product-list2">
            {products.length > 0 ? (
              products.map((product) => (
                <Product
                  key={product.id}
                  product={[product]}
                  totalProductCount={products.length}
                />
              ))
            ) : (
              <p>Aucun produit disponible pour ce type.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllProductsPage;
