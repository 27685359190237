/*import React, { useState, useRef, useEffect } from "react";
import { IconButton, Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
interface GoogleMapsSearchModalProps {
  latitude: number;
  longitude: number;
  onLocationSelect?: (location: { lat: number; lng: number; address: string }) => void;
  isOpen: boolean;
  onClose: () => void;
}

const GoogleMapsSearchModal: React.FC<GoogleMapsSearchModalProps> = ({ latitude, longitude, onLocationSelect, isOpen, onClose }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const googleMapRef = useRef<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isMapInitialized, setIsMapInitialized] = useState(false);

  useEffect(() => {
    console.log("window.google:", window.google);
  }, []);

  // Load the Google Maps script if not already loaded
  useEffect(() => {
    if (!window.google?.maps) {
      const script = document.createElement("script");
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBp3Fn1fzjsPGPZFJOfG7jntex0CWcyv3o&libraries=places";
      script.async = true;
      script.defer = true;

      script.onload = () => setIsScriptLoaded(true);
      script.onerror = () => console.error("Error loading Google Maps script");

      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    } else {
      setIsScriptLoaded(true);
    }
  }, []);

  const initializeMap = () => {
    if (!mapRef.current || !window.google || !window.google.maps) {
      return;
    }

    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: latitude, lng: longitude },
      zoom: 15,
    });

    googleMapRef.current = map;

    const marker = new window.google.maps.Marker({
      position: { lat: latitude, lng: longitude },
      map: map,
      draggable: true,
    });

    markerRef.current = marker;

    setIsMapInitialized(true);
  };
  console.log("Map ref:", mapRef.current);
  console.log("Google API:", window.google);

  useEffect(() => {
    if (isOpen) {
      setIsMapInitialized(false);  // Reset state
      setTimeout(() => setIsMapInitialized(true), 500); // Reinitialize map
    }
  }, [isOpen]);

  const handleGeocoding = async (lat: number, lng: number) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results && results[0]) {
        onLocationSelect?.({ lat, lng, address: results[0].formatted_address });
        onClose();  // Appeler onClose ici pour fermer le modal
      }
    });
  };
  

  useEffect(() => {
    if (isOpen && isScriptLoaded && !isMapInitialized) {
      const initialize = () => {
        if (mapRef.current) {
          initializeMap();
        }
      };

      // Use requestAnimationFrame or timeout to ensure proper re-render timing
      window.requestAnimationFrame(initialize);

      // Ensure a fallback timeout for initialization
      const timeoutId = setTimeout(initialize, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [isOpen, isScriptLoaded, latitude, longitude, isMapInitialized]);

  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby="google-maps-modal">
      <div style={modalStyle}>
        <IconButton onClick={onClose} style={closeButtonStyle}>
          <CloseIcon />
        </IconButton>


        {isScriptLoaded ? (
          <div ref={mapRef} style={{ width: "100%", height: "650px", borderRadius: "10px" }} />
        ) : (
          <p>Loading Google Maps...</p>
        )}
      </div>
    </Modal>
  );
};

const modalStyle: React.CSSProperties = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  backgroundColor: "white",
  borderRadius: "10px",
  boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
  padding: "20px",
  zIndex: 9999,
};

const closeButtonStyle: React.CSSProperties = {
  position: "absolute",
  top: "10px",
  right: "10px",
  zIndex: 99999,
};

export default GoogleMapsSearchModal;*/


import React, { useState, useRef, useEffect } from "react";
import { IconButton, Modal, TextField, Box, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { SxProps, Theme } from '@mui/material/styles';
interface GoogleMapsSearchModalProps {
  onLocationSelect?: (location: { lat: number; lng: number; address: string }) => void;
  isOpen: boolean;
  onClose: () => void;
}

const DEFAULT_LOCATION = { lat: 5.345317, lng: -4.024429 };

const GoogleMapsSearchModal: React.FC<GoogleMapsSearchModalProps> = ({
  onLocationSelect,
  isOpen,
  onClose,
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const googleMapRef = useRef<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(DEFAULT_LOCATION);
  const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number; address: string } | null>(null);
  const [message, setMessage] = useState("Localisation en cours...");
  
  useEffect(() => {
    if (!window.google?.maps) {
      const script = document.createElement("script");
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBp3Fn1fzjsPGPZFJOfG7jntex0CWcyv3o&libraries=places";
      script.async = true;
      script.defer = true;
      script.onload = () => setIsScriptLoaded(true);
      script.onerror = () => console.error("Erreur de chargement de Google Maps");
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    } else {
      setIsScriptLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isScriptLoaded) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setMessage("Cliquez sur un point ou recherchez une adresse");
          initializeMap(position.coords.latitude, position.coords.longitude);
        },
        () => {
          setMessage("Localisation impossible, position par défaut utilisée");
          initializeMap(DEFAULT_LOCATION.lat, DEFAULT_LOCATION.lng);
        }
      );
    }
  }, [isScriptLoaded]);

  const initializeMap = (lat: number, lng: number) => {
    if (!mapRef.current || !window.google || !window.google.maps || !searchInputRef.current) return;

    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat, lng },
      zoom: 10,
    });
    googleMapRef.current = map;

    const marker = new window.google.maps.Marker({
      position: { lat, lng },
      map,
      draggable: true,
    });
    markerRef.current = marker;

    map.addListener("dblclick", (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        map.setCenter(event.latLng);
        map.setZoom((map.getZoom() || 14) + 1);
      }
    });

    map.addListener("click", (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        marker.setPosition(event.latLng);
        handleGeocoding(event.latLng.lat(), event.latLng.lng());
      }
    });

    const input = searchInputRef.current;
    const autocomplete = new window.google.maps.places.Autocomplete(input);
    autocomplete.bindTo("bounds", map);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        marker.setPosition({ lat, lng });
        map.setCenter({ lat, lng });
        handleGeocoding(lat, lng);
      }
    });
  };

  const handleGeocoding = async (lat: number, lng: number) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results && results[0]) {
        const address = results[0].formatted_address;
        setSelectedLocation({ lat, lng, address });
      } else {
        console.error("Adresse non trouvée");
      }
    });
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter" && searchInputRef.current === document.activeElement) {
      const input = searchInputRef.current?.value;
      if (input) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: input }, (results, status) => {
          if (status === "OK" && results && results[0]) {
            const location = results[0].geometry.location;
            markerRef.current?.setPosition(location);
            googleMapRef.current?.setCenter(location);
            handleGeocoding(location.lat(), location.lng());
          }
        });
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleConfirmLocation = () => {
    if (selectedLocation && onLocationSelect) {
      onLocationSelect(selectedLocation);
      onClose();
    }
  };
  const handleConfirm = () => {
    if (selectedLocation && onLocationSelect) {
      onLocationSelect(selectedLocation);
      onClose();
    }
  };

  const handleModalClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        <IconButton onClick={onClose} sx={closeButtonStyle}>
          <CloseIcon />
        </IconButton>
        <TextField
          inputRef={searchInputRef}
          placeholder="Rechercher une adresse"
          fullWidth
          sx={{ mb: 2, width: "50%", display: "block" 
          }}

        />
        <Box ref={mapRef} sx={{ width: "100%", height: "500px", borderRadius: "10px" }} />
        {selectedLocation && (
          <Button variant="contained" sx={confirmButtonStyle} onClick={handleConfirm}>
            Confirmer
          </Button>
        )}
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  backgroundColor: "white",
  borderRadius: "10px",
  padding: "20px",
  boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
};

const closeButtonStyle = {
  
  position: "absolute",
  top: "20px",
  right: "20px",
};

const confirmButtonStyle = {
  position: "absolute",
  bottom: "75%",
  left: "50%",
  transform: "translateX(-50%)",
  width: "200px",
};

export default GoogleMapsSearchModal;
