import React, { useEffect, useState, useCallback } from 'react';
import { getProductById, getShopByForProduct } from '../../api/requests/ProductRequests';
import { Product as ProductType } from '../../types/Products';
import ModalContent from './ModalContent';
import { useCart } from '../HomePage/CartContext';
import './ProductDetail.css';

interface ProductDetailProps {
    productId: string;
    onClose: () => void;
    onNext: () => void;
    onPrevious: () => void;
    showNextButton: boolean;
    showPreviousButton: boolean;
    nextProductId?: string;
    previousProductId?: string;
}

const ProductDetail: React.FC<ProductDetailProps> = ({
    productId,
    onClose,
    onNext,
    onPrevious,
    showNextButton,
    showPreviousButton,
    nextProductId,
    previousProductId,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [productData, setProductData] = useState<{
        product: ProductType | null;
        shopByLabels: { label: string; color: string }[];
    }>({ product: null, shopByLabels: [] });
    const [showQuantityControls, setShowQuantityControls] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const [isModalVisible, setModalVisible] = useState(false);
    const [activeTab, setActiveTab] = useState<'description' | 'nutrition'>('description');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);
    const { addToCart, updateQuantity, removeFromCart, cartItems } = useCart();

    const productCache = new Map<string, { product: ProductType; shopByLabels: { label: string; color: string }[] }>();
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 992);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const loadProductData = useCallback(async (id: string, setLoading = true) => {
        if (productCache.has(id)) {
            return productCache.get(id)!;
        }

        if (setLoading) setIsLoading(true);
        try {
            const [product, shopBy] = await Promise.all([getProductById(id), getShopByForProduct(id)]);
            const data = { product, shopByLabels: shopBy.map(item => ({ label: item.label, color: item.color || 'black' })) };
            productCache.set(id, data);
            return data;
        } catch (error) {
            throw new Error('Error loading product');
        } finally {
            if (setLoading) setIsLoading(false);
        }
    }, []);

    const preloadAdjacentProducts = useCallback(async () => {
        if (nextProductId && !productCache.has(nextProductId)) {
            loadProductData(nextProductId, false).catch(() => { });
        }
        if (previousProductId && !productCache.has(previousProductId)) {
            loadProductData(previousProductId, false).catch(() => { });
        }
    }, [nextProductId, previousProductId, loadProductData]);

    useEffect(() => {
        const loadData = async () => {
            try {
                const data = await loadProductData(productId);
                setProductData(data);
                if (data.product) {
                    const cartItem = cartItems.find(item => item.id === data.product.id);
                    if (cartItem) {
                        setQuantity(cartItem.quantity);
                        setShowQuantityControls(true);
                    } else {
                        setQuantity(0);
                        setShowQuantityControls(false);
                    }
                }
                preloadAdjacentProducts();
            } catch (err) {
                setError('Error loading product');
            }
        };

        loadData();
    }, [productId, cartItems, loadProductData, preloadAdjacentProducts]);

    const handleQuantityChange = (newQuantity: number) => {
        if (!productData.product) return;
        setQuantity(newQuantity);
        if (newQuantity > 0) {
            const cartItem = {
                id: productData.product.id,
                name: productData.product.name,
                quantity: newQuantity,
                price: parseFloat(productData.product.price.toString()),
                media: productData.product.media,
                brand: productData.product.brand,
                unitSize: productData.product.unitSize,
                unitPrice: productData.product.unitPrice,
                shopByIds: productData.product.shopByIds,
            };
            if (quantity === 0) {
                addToCart(cartItem, newQuantity);
            } else {
                updateQuantity(productData.product.name, newQuantity);
            }
        } else {
            removeFromCart(productData.product.name);
            setShowQuantityControls(false);
        }
    };

    const handleAddToBag = () => {
        if (productData.product && !showQuantityControls) {
            setShowQuantityControls(true);
            handleQuantityChange(1);
        }
    };

    const calculateTotal = () => {
        if (productData.product) {
            const effectiveQuantity = quantity > 0 ? quantity : 1;
            return (parseFloat(productData.product.price.toString()) * effectiveQuantity).toLocaleString('fr-FR');
        }
        return '0';
    };

    function isImageUrl(url: string | undefined): boolean {
        if (!url) return false;
        return /\.(jpg|jpeg|png|gif)$/i.test(url);
    }


    const discountPercentage = productData.product
        ? productData.product.unitPrice && productData.product.price
            ? ((productData.product.unitPrice - productData.product.price) / productData.product.unitPrice) * 100
            : 0
        : 0;

    const renderContent = () => {
        if (error) {
            return <div className="errorMessage">{error}</div>;
        }

        const { product } = productData;
        if (!product) return null;

        return (
            <div className="Container">
                <div className="productHeader">
                    {/* Première colonne */}
                    <div className="productDetailsColumn">
                        <h2 className="productName">{product.name}</h2>
                        <div className="productInfo">
                            <span className="price">{product.price} XOF/kg</span>
                            <span className="originalPrice">{product.unitPrice} XOF/kg</span>

                            {discountPercentage > 0 && (
                                <span className="discount-red">Save {discountPercentage.toFixed(0)}%</span>
                            )}


                        </div>
                        <div className="shopByContainer">
                            {productData.shopByLabels.map((shopBy, index) => (
                                <span key={index} style={{ color: shopBy.color }}>
                                    {shopBy.label}
                                    {index !== productData.shopByLabels.length - 1 && ' | '}
                                </span>
                            ))}
                        </div>
                    </div>

                    {/* Deuxième colonne */}
                    <div className="productImageColumn">
                        {product.media?.endsWith('.mp4') ? (
                            <span>Video not supported</span>
                        ) : (
                            <img src={product.media} alt={product.media} />
                        )}
                    </div>
                </div>

                <div className="quantityAndInfoContainer">
                    {/* Première colonne - Quantité, Total, Description */}
                    <div className="quantityAndInfoColumn">
                        <div className="totalContainer">
                            <div className="productInfo">
                                <span className="price">Total: {calculateTotal()} XOF</span>
                                <span className="originalPrice">{product.unitPrice} XOF/kg</span>
                            </div>
                        </div>
                        <div className="quantityContainer">
                            {showQuantityControls ? (
                                <div className="addToCartWithQuantity">
                                    <button
                                        className="quantityButton quantityButtonLeft"
                                        onClick={() => handleQuantityChange(quantity - 1)}
                                    >
                                        -
                                    </button>
                                    <span className="quantityText">{quantity}</span>
                                    <button
                                        className="quantityButton quantityButtonRight"
                                        onClick={() => handleQuantityChange(quantity + 1)}
                                    >
                                        +
                                    </button>
                                </div>
                            ) : (
                                <button className="addToCartButton" onClick={handleAddToBag}>
                                    Add To Bag
                                </button>
                            )}
                        </div>

                        {/* Description - Responsive */}
                        {!isMobile ? (
                            <div className="descriptionContainer">
                                <p>{product.description}</p>
                            </div>
                        ) : (
                            <button className="detailsButton2" onClick={() => {
                                setActiveTab('description')
                                setModalVisible(true);
                            }}>
                                Description
                            </button>
                        )}
                    </div>

                    {/* Deuxième colonne - Bouton Nutrition */}
                    <div className="buttons-container">
                        <button className="detailsButton1" onClick={() => {
                            setActiveTab('description')
                            setModalVisible(true);
                        }}>
                            Description
                        </button>
                        <button className="detailsButton" onClick={() => {
                            setActiveTab('nutrition');
                            setModalVisible(true);
                        }}>
                            Nutrition
                        </button>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div className="productDetails">
            {renderContent()}
            {isModalVisible && (
                <div className="modalContainer">
                    <div className="modalContent">

                        <ModalContent
                            activeTab={activeTab}
                            onTabChange={setActiveTab}
                            content={
                                activeTab === 'description'
                                    ? productData.product?.description
                                    : productData.product?.nutrition ? (
                                        <img
                                            src={productData.product.nutrition}
                                            alt="Nutrition information"
                                            style={{ width: '100%', height: 'auto' }}
                                        />
                                    ) : (
                                        <p>Aucune information nutritionnelle disponible.</p>
                                    )
                            }
                            onClose={() => setModalVisible(false)}
                        />


                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductDetail;
