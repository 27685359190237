import React, { useState, useEffect } from 'react';
import './Product.css';
import QuantitySelector from './QuantitySelector/QuantitySelector';
import { useCart } from '../HomePage/CartContext';
import { Product as ProductType } from '../../types/Products';
import { getShopByForProduct } from '../../api/requests/ProductRequests';
import { useNavigate } from 'react-router-dom';

interface ProductProps {
  product: ProductType[];
  className?: string;
  totalProductCount?: number;
}

const Product: React.FC<ProductProps> = ({ product, className, totalProductCount }) => {
  const { cartItems, addToCart, updateQuantity, removeFromCart } = useCart();
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
  const [notification, setNotification] = useState<string | null>(null);
  const [shopByLabels, setShopByLabels] = useState<{ [productId: string]: { label: string; color: string }[] }>({});
  const [loadingShopBy, setLoadingShopBy] = useState<boolean>(true);
  const [errorLoadingShopBy, setErrorLoadingShopBy] = useState<string | null>(null);

  const navigate = useNavigate();

  const [lastInRow, setLastInRow] = useState<number[]>([]);



  useEffect(() => {
    const calculateLastInRow = () => {
      const containerWidth = document.querySelector(".product-list")?.clientWidth || 0;
      const productWidth = 200; // Largeur approximative d'un produit (avec marges, padding, etc.)
      const productsPerRow = Math.max(1, Math.floor(containerWidth / productWidth)); // Assurez-vous d'avoir au moins 1 produit par ligne.

      const newLastInRow: number[] = [];
      let currentIndex = 0;

      while (currentIndex < product.length) {
        // Dernier produit visible dans chaque ligne
        const lastVisibleIndex = Math.min(currentIndex + productsPerRow - 1, product.length - 1);
        newLastInRow.push(lastVisibleIndex);
        currentIndex += productsPerRow;
      }

      setLastInRow(newLastInRow);
    };

    // Recalcule au redimensionnement de la fenêtre
    window.addEventListener("resize", calculateLastInRow);
    calculateLastInRow();

    return () => window.removeEventListener("resize", calculateLastInRow);
  }, [product])
  useEffect(() => {
    const fetchShopByForProduct = async (productId: string) => {
      try {
        const productShopBy = await getShopByForProduct(productId);
        if (productShopBy && productShopBy.length > 0) {
          setShopByLabels((prev) => ({
            ...prev,
            [productId]: productShopBy.map((shopBy) => ({
              label: shopBy.label,
              color: shopBy.color || 'black',
            })),
          }));
        }
      } catch (err) {
        setErrorLoadingShopBy(`Erreur lors du chargement des labels pour le produit ${productId}.`);
      }
    };

    const fetchShopByData = async () => {
      setLoadingShopBy(true);
      try {
        if (!product || product.length === 0) return;
        await Promise.all(product.map((product) => fetchShopByForProduct(product.id)));
      } catch (err) {
        setErrorLoadingShopBy('Erreur lors du chargement des labels.');
      } finally {
        setLoadingShopBy(false);
      }
    };

    fetchShopByData();
  }, [product]);

  useEffect(() => {
    const updatedQuantities: { [key: string]: number } = {};
    cartItems.forEach((item) => {
      updatedQuantities[item.name] = item.quantity;
    });
    setQuantities(updatedQuantities);
  }, [cartItems]);

  const handleAddToCart = (product: ProductType, quantity: number) => {
    const cartItem = {
      id: product.id,
      name: product.name,
      quantity,
      price: parseFloat(product.price.toString()),
      media: product.media,
      brand: product.brand,
      unitSize: product.unitSize,
      unitPrice: product.unitPrice,
      shopByIds: product.shopByIds,
    };
    addToCart(cartItem, quantity);
    setNotification(`${quantity} x ${product.name} ajouté au panier !`);
    setTimeout(() => setNotification(null), 3000);
  };

  const handleQuantityChange = (productName: string, quantity: number) => {
    setQuantities((prev) => ({ ...prev, [productName]: quantity }));
    const productToUpdate = product.find((p) => p.name === productName);

    if (productToUpdate) {
      if (quantity > 0) {
        // Vérifier si le produit est déjà dans le panier
        const existingItem = cartItems.find(item => item.name === productName);

        if (existingItem) {
          // Si le produit existe, mettre à jour la quantité
          updateQuantity(productName, quantity);
        } else {
          // Si le produit n'existe pas, l'ajouter au panier
          handleAddToCart(productToUpdate, quantity);
        }
      } else {
        removeFromCart(productName);
      }
    }
  };

  const handleProductClick = (productId: string, productName: string) => {
    navigate(`/product/${productId}`);
  };

  return (
    <div>
      {notification && <div className="notification">{notification}</div>}
      {loadingShopBy ? (
        <div>Chargement des informations...</div>
      ) : errorLoadingShopBy ? (
        <div>{errorLoadingShopBy}</div>
      ) : (
        <div className="product-list">
          {product.length > 0 ? (
            product.map((Product, index) => {
              const isLastInRow = lastInRow.includes(index);
              return (
                <div className={`product-item ${isLastInRow ? "no-border" : ""}`} key={Product.id}>
                  <div onClick={() => handleProductClick(Product.id, Product.name)}>
                    <div className="flex justify-center mb-4">
                      {Product.media && (Product.media.endsWith('.mp4') || Product.media.endsWith('.mov')) ? (
                        <video src={Product.media} className="product-image" controls />
                      ) : (
                        <img src={Product.media} alt={Product.name} className="product-image" />
                      )}

                    </div>
                    <div className="shop-by-labels">
                      {shopByLabels[Product.id] && shopByLabels[Product.id].length > 0
                        ? shopByLabels[Product.id].map((shopBy, i) => (
                          <span key={i} style={{ fontSize: 14, color: shopBy.color }}>
                            {shopBy.label}
                            {i !== shopByLabels[Product.id].length - 1 && <span className="vertical-divider"> | </span>}
                          </span>
                        ))
                        : 'Aucun label disponible'}
                    </div>
                    <div>
                      <p className="product-brand">{Product.brand}</p>
                      <p className="product-name">{Product.name}</p>
                    </div>
                    <div>
                      <p style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="product-unit-size">{Product.unitSize}</span>
                        <div className="vertical-line"></div>
                        <span className="original-price">{Product.unitPrice} XOF</span>
                      </p>
                    </div>
                  </div>
                  <div className="product-card-container">
                    <p>
                      <strong className="current-price">{Product.price} XOF</strong>
                    </p>
                    <div className="right-container">
                      <QuantitySelector
                        initialQuantity={quantities[Product.name] || 0}
                        onQuantityChange={(quantity) => handleQuantityChange(Product.name, quantity)}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p>Aucun produit disponible.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Product;
