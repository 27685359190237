import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Logo, SearchBar, CartButton, DeliverySection } from '../TopLine/TopLine';
import { useCart } from '../../CartContext';
import { itemsToSearch } from '../TopLine/itemsToSearch';
import CartModal from '../../Cart';
import MenuIcon from '../Navbar/MenuIcon';

import Dropdown from '../../../Dropdown/Dropdown';

import CreateAccount from '../Account/CreateAccount/CreateAccount';
import SignInModal from '../Account/SignInModal/SignInModal';
import ModalWindow from '../Delivery/Delivery';
import { getNavItems, getNavItemsByType } from '../../../../api/requests/navItemRequests';


interface NavItem {
  id: string;
  label: string;
  icon: string;
}
export interface Location {
  latitude: string;
  longitude: string;
  address: string;
}

const Navbar2: React.FC = () => {
  const navbarRef = useRef<HTMLDivElement>(null);
  const [navbar, setNavbar] = useState<NavItem[]>([]); // L'état pour les items de navigation
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 992);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [containerMargins, setContainerMargins] = useState({ left: 0, right: 0 });
  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false);
  const [isCreateAccountModalOpen, setIsCreateAccountModalOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [isCartModalOpen, setIsCartModalOpen] = useState<boolean>(false);
  const [dropdownTop, setDropdownTop] = useState<number>(0);
  const [navItemId, setNavItemId] = useState<string | null>(null);
  const [dropdownTimeout, setDropdownTimeout] = useState<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();
  const { cartItems } = useCart();
  const handleMenuToggle = () => setIsMenuOpen(prev => !prev);
  const closeAddressModal = () => setIsAddressModalOpen(false);
  const [selectedAddress, setSelectedAddress] = useState<string>("");

  const handleLocationSelect = (location: Location) => {
    setSelectedAddress(location.address); // Access 'address' from Location
    closeAddressModal(); // Close the modal after selecting the address
  };

  const openAddressModal = () => {
    console.log("Opening Address Modal...");
    setIsAddressModalOpen(true);
  };

  const formatLabelForUrl = (label: string) => {
    return label
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/&/g, 'and')
      .replace(/@/g, '%40');
  };
  // Fonction pour récupérer les NavItems via l'API
  useEffect(() => {
    const fetchNavItems = async () => {
      try {
        // Fetch NavItems by type "RESIDENTIAL"
        const items = await getNavItemsByType("RESIDENTIAL");
        setNavbar(items);
      } catch (error) {
        console.error("Erreur lors de la récupération des NavItems:", error);
      }
    };

    fetchNavItems(); // Fetch NavItems on component mount
  }, []); // Only run once on mount

  const updateDropdownPosition = () => {
    if (!navbarRef.current) return;

    const navbarRect = navbarRef.current.getBoundingClientRect();
    const container = navbarRef.current.querySelector('.container-fluid');

    if (container) {
      const containerRect = container.getBoundingClientRect();
      setContainerMargins({
        left: containerRect.left,
        right: window.innerWidth - containerRect.right
      });
    }

    setDropdownTop(navbarRect.bottom);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
      updateDropdownPosition();
    };

    const handleScroll = () => {
      updateDropdownPosition();
    };

    // Initial position update
    updateDropdownPosition();

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);




  useEffect(() => {
    if (activeDropdown !== null) {
      updateDropdownPosition();
    }
  }, [activeDropdown]);

  const handleNavItemHover = (index: number | null) => {
    if (!isMobile) {
      if (dropdownTimeout) {
        clearTimeout(dropdownTimeout); // Annule le timeout précédent
        setDropdownTimeout(null);
      }

      if (index !== null) {
        setActiveDropdown(index); // Ouvre le dropdown sur le nouvel élément
      }
    }
  };

  const handleMouseLeaveMenu = () => {
    if (!isMobile) {
      // Fermer le dropdown uniquement si aucun autre élément n'est survolé
      setActiveDropdown(null); // Fermer le dropdown lorsque la souris quitte toute la zone du menu
    }
  };






  const handleDropdownClick = (
    e: React.MouseEvent,
    id: number,
    label: string
  ) => {
    e.preventDefault();
    setActiveDropdown(null);
    const labelSlug = formatLabelForUrl(label);
    navigate(`/department/${labelSlug}`);
    setIsMenuOpen(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value) {
      const results = itemsToSearch.filter(item =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-white" style={{ backgroundColor: '#ffffff' }}>
        <div className="container-fluid d-flex flex-column">
          {/* Top Navbar (Logo, Menu, Cart) */}
          <div className="navbar-top d-flex align-items-center justify-content-between w-100">
            {isMobile && <MenuIcon isMenuOpen={isMenuOpen} onToggle={handleMenuToggle} />}
            {isMobile && <Logo />}
            {isMobile && (
              <div className="cart-button-container">
                <CartButton cartItems={cartItems} onClick={() => setIsCartModalOpen(true)} />
              </div>
            )}
          </div>

          {/* Search Bar for Mobile */}
          {isMobile && (
            <div className="search-bar-container w-100">
              <SearchBar
                searchValue={searchValue}
                onChange={handleSearchChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                clearSearch={() => setSearchValue('')}
                isFocused={isFocused}
              />
            </div>
          )}

          {/* Mobile Menu */}
          {/* Navigation menu */}
          <div
            className={`navbar-menu ${isMenuOpen ? 'show' : 'hide'}`}
            id="navbarNav"
            onMouseLeave={handleMouseLeaveMenu} // Gère la sortie de la souris du menu

          >
            {isMenuOpen && isMobile && (
              <>
                <button className="close-btn" onClick={handleMenuToggle}>
                  &times;
                </button>
                <div className="delivery-section">
                  <img
                    src="/favicon.ico"
                    alt="Go to homepage"
                    width="23"
                    height="23"
                    className="delivery-icon"
                  />
                  <DeliverySection
                    onOpenAddressModal={openAddressModal}
                    selectedAddress={selectedAddress} // Pass the address here
                  />
                </div>

                <div className="account-popup-menu">
                  <button className="account-link" onClick={() => setIsSignInModalOpen(true)}>
                    Sign In
                  </button>
                  <span className="or-text">or</span>
                  <button className="account-link" onClick={() => setIsCreateAccountModalOpen(true)}>
                    Create an Account
                  </button>
                </div>
              </>
            )}
            <ul className="navbar-nav ms-auto">
              {navbar.map((item, index) => (
                <li
                  key={index}
                  className="nav-item"
                  onMouseEnter={() => handleNavItemHover(index)}
                >
                  <Link
                    to="#"
                    className="nav-link text-dark d-flex flex-column align-items-center"
                    onClick={(e) => handleDropdownClick(e, index, item.label)}
                  >
                    <div className="icon-container">
                      <img src={item.icon} alt={item.label} className="nav-icon" />
                    </div>
                    <div className="label-button-container">
                      <span className="nav-label">{item.label}</span>
                      <button
                        className={`GlobalNav_nav_item_caret ${activeDropdown === index ? 'open' : ''}`}
                        type="button"
                        aria-haspopup="true"
                        aria-expanded={activeDropdown === index ? 'true' : 'false'}
                      >
                        <img
                          src="/assets/SVG/chevron.png"
                          alt="Chevron"
                          className={`chevron-icon ${activeDropdown === index ? 'open' : ''}`}
                        />
                      </button>
                    </div>
                  </Link>

                  {!isMobile && activeDropdown === index && (
                    <div
                      className="dynamic-dropdown"
                      style={{
                        position: 'fixed',
                        top: `${dropdownTop}px`,
                        left: `${containerMargins.left}px`,
                        right: `${containerMargins.right}px`,
                        width: 'auto'
                      }}
                    >
                      <Dropdown
                        navItemId={item.id}
                        closeDropdown={() => setActiveDropdown(null)}
                      />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>

      {/* Modals */}
      <SignInModal
        isOpen={isSignInModalOpen}
        onClose={() => setIsSignInModalOpen(false)}
      />
      <CreateAccount
        isOpen={isCreateAccountModalOpen}
        onClose={() => setIsCreateAccountModalOpen(false)}
      />
      <CartModal isOpen={isCartModalOpen} onClose={() => setIsCartModalOpen(false)} />
      <ModalWindow
        isOpen={isAddressModalOpen}
        onClose={closeAddressModal}
        onLocationSelected={handleLocationSelect}
      />

    </header>
  );
};

export default Navbar2;
