import React, { useState, useEffect } from 'react';
import { useCart } from './CartContext';
import './Cart.css';
import QuantitySelector from '../Categories/QuantitySelector/QuantitySelector';
import { FaTrash } from 'react-icons/fa';
import { getShopByForProduct } from '../../api/requests/ProductRequests';
import { baseUrl } from '../../api/client';

interface CartProps {
  onClose: () => void;
  isOpen: boolean;
}

const CartModal: React.FC<CartProps> = ({ onClose, isOpen }) => {
  const { cartItems, updateQuantity, removeFromCart } = useCart();
  const [notification, setNotification] = useState<string | null>(null);
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
  const [shopByLabels, setShopByLabels] = useState<{ [productId: string]: { label: string, color: string }[] }>({}); // Updated structure
  const [loadingShopBy, setLoadingShopBy] = useState<boolean>(false); // Indicate loading state

  // Calcul du nombre total de produits (quantité totale de tous les articles)
  const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);

  const fetchShopByForCartItems = async () => {
    setLoadingShopBy(true);
    try {
      const labels: { [productId: string]: { label: string, color: string }[] } = {}; // New structure with color

      for (const item of cartItems) {
        // Récupérer les ShopBy pour chaque produit
        const productShopBy = await getShopByForProduct(item.id);

        // Log pour vérifier ce qui est retourné par l'API
        console.log(`ShopBy pour le produit ${item.id}:`, productShopBy);

        // Si le produit a des ShopBy, on les stocke, sinon on affiche un message par défaut
        if (productShopBy.length > 0) {
          labels[item.id] = productShopBy.map(shopBy => ({
            label: shopBy.label,
            color: shopBy.color || 'black',  // Assurez-vous que "color" est bien récupéré
          }));
        } else {
          labels[item.id] = [{ label: 'Aucun label disponible', color: 'gray' }];
        }
      }

      // Mettre à jour l'état avec les labels ShopBy récupérés
      setShopByLabels(labels);
    } catch (err) {
      console.error('Erreur lors de la récupération des ShopBy pour les produits:', err);
    } finally {
      setLoadingShopBy(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchShopByForCartItems();
    }
  }, [isOpen, cartItems]);

  const handleRemoveFromCart = (productName: string) => {
    removeFromCart(productName);
    setQuantities((prev) => ({ ...prev, [productName]: 0 }));
    setNotification(`${productName} a été supprimé du panier.`);
    setTimeout(() => setNotification(null), 3000);
  };

  const handleQuantityChange = (itemName: string, newQuantity: number) => {
    if (newQuantity === 0) {
      handleRemoveFromCart(itemName); // Supprime l'article si la quantité devient zéro
    } else {
      updateQuantity(itemName, newQuantity); // Sinon, met à jour la quantité dans le panier
    }
  };

  return (
    <div className={`modal-overlay ${isOpen ? 'active' : ''}`}>
      <div className={`cart-sidebar ${isOpen ? 'slide-in' : 'slide-out'}`}>
        <div className="cart-header">
          <h2>Panier ({totalQuantity})</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>

        <div className="cart-body">
          {cartItems.length === 0 ? (
            <div className="text-center empty-cart">
              <h1>Your bag is empty</h1>
              <span>Let’s find something for you to enjoy!</span>
            </div>
          ) : (
            <div className="product">
              {cartItems.map((item) => (
                <div key={item.name} className="cart-product-item">
                  <div className="cart-item">
                    {item.media && item.media.toLowerCase().endsWith('.mp4') ? (
                      <video src={`${baseUrl}${item.media}`} className="cart-product-image" />
                    ) : (

                     
                      <img
                        src={item.media || 'default-image.png'}
                        alt={item.name}
                        className="cart-product-image"
                      />
                    )}
                  </div>
                  <div className="cart-product-details">
                    <div className="cart-shop-by-labels">
                      {loadingShopBy ? (
                        <p>Chargement des labels...</p>
                      ) : (
                        shopByLabels[item.id] && shopByLabels[item.id].length > 0 ? (
                          shopByLabels[item.id].map((shopBy, i) => (
                            <span key={i} style={{ fontSize: 14, color: shopBy.color }}>
                              {shopBy.label}
                              {i !== shopByLabels[item.id].length - 1 && <span className="cart-vertical-divider"> | </span>}
                            </span>
                          ))
                        ) : (
                          <span>Aucun label disponible</span>
                        )
                      )}
                    </div>

                    <div>
                      <p className="cart-product-brand">{item.brand}</p>
                      <p className="cart-product-name">{item.name}</p>
                    </div>

                    <p style={{ display: 'flex', alignItems: 'center' }}>
                      <span className="cart-product-unit-size">{item.unitSize}</span>
                      <div className="cart-vertical-line"></div>
                      <span className="cart-original-price">{item.unitPrice}  XOF</span>
                    </p>

                    <div className="cart-product-card-container">
                      <div className="price-quantity-container" style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginRight: '10px' }}>
                          <strong className="cart-current-price">{item.price.toFixed(2)} XOF</strong>
                        </p>
                        <QuantitySelector
                          initialQuantity={item.quantity}
                          onQuantityChange={(quantity) => handleQuantityChange(item.name, quantity)}
                        />
                        <button
                          onClick={() => handleRemoveFromCart(item.name)}
                          className="remove-button"
                          style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                        >
                          <FaTrash style={{ color: 'red', fontSize: '20px' }} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="click-outside" onClick={onClose}></div>
    </div>
  );
};

export default CartModal;
