import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProductDetail from '../Components/ProductDetail/ProductDetail';
import { Product as ProductType } from '../types/Products';
import { getProductById, getRelatedProducts } from '../api/requests/ProductRequests';
import Breadcrumbs from '../Components/Categories/Breadcrumbs/Breadcrumbs';
import { getSubcategoryById } from '../api/requests/subcategoryRequests';
import { getCategoryById } from '../api/requests/categorieRequests';
import { getMenuDataById } from '../api/requests/menuDataRequests';
import { getNavItemById } from '../api/requests/navItemRequests';
import './ProductDetailPage.css';
import Product from '../Components/Categories/Product';

const ProductDetailPage: React.FC = () => {
  const [product, setProduct] = useState<ProductType | null>(null);
  const [breadcrumbItems, setBreadcrumbItems] = useState<{ label: string; path?: string }[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [relatedProducts, setRelatedProducts] = useState<ProductType[]>([]);
  const { productId } = useParams<{ productId: string }>();

  useEffect(() => {
    const fetchData = async () => {
      if (productId) {
        try {
          setLoading(true);

          // Fetch product details
          const productData = await getProductById(productId);
          setProduct(productData);


          // Load subcategory
          let subcategory;
          try {
            subcategory = await getSubcategoryById(productData.subcategoryId);
            console.log("Subcategory loaded:", subcategory);
          } catch (err) {
            console.error("Error loading subcategory:", err);
            setError('Unable to load subcategory');
            return;
          }

          // Load category
          let category;
          try {
            category = await getCategoryById(subcategory.categoryId);
            console.log("Category loaded:", category);
          } catch (err) {
            console.error("Error loading category:", err);
            setError('Unable to load category');
            return;
          }

          // Load menu data
          let menuData;
          try {
            menuData = await getMenuDataById(category.menuDataId);
            console.log("MenuData loaded:", menuData);
          } catch (err) {
            console.error("Error loading menu:", err);
            setError('Unable to load menu');
            return;
          }

          // Load navigation item
          let navItem;
          try {
            navItem = await getNavItemById(menuData.navItemId);
            console.log("NavItem loaded:", navItem);
          } catch (err) {
            console.error("Error loading NavItem:", err);
            setError('Unable to load navigation item');
            return;
          }

          // Build breadcrumb items
          const items = [
            { label: navItem.label, path: `/department/${navItem.label}` },
            { label: menuData.label, path: `/${navItem.label}/${menuData.label}` },
            { label: category.label },
          ];
          setBreadcrumbItems(items);

        } catch (err) {
          console.error("Error loading product data:", err);
          setError('Unable to load product data');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [productId]);

  const fetchRelatedProducts = async () => {
    if (productId) {
      try {
        console.log("Fetching related products for product ID:", productId);
        const relatedData = await getRelatedProducts(productId);
        console.log("Related products API response:", relatedData);
    
        // Vérifiez si la réponse est un tableau et si la longueur est supérieure à zéro
        if (Array.isArray(relatedData) && relatedData.length > 0) {
          setRelatedProducts(relatedData);  // Mise à jour avec la liste des produits associés
        } else {
          console.log("No related products found.");
          setRelatedProducts([]);  // Aucun produit associé
        }
      } catch (err) {
        console.error("Error fetching related products:", err);
      }
    }
  };
  
  
  useEffect(() => {
    console.log("Product ID:", productId); // Vérifiez l'ID du produit
    fetchRelatedProducts();
  }, [productId]);
  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!product) return <div>Product not found</div>;

  return (
    <div style={{ marginLeft: '10px', marginRight: '10px' }}>
      <div className="product-detail-breadcrumb">
        <Breadcrumbs breadcrumbs={breadcrumbItems} />
      </div>
      <ProductDetail
        productId={productId!}
        onClose={() => { }}
        onNext={() => { }}
        onPrevious={() => { }}
        showNextButton={false}
        showPreviousButton={false}
      />
      {relatedProducts.length > 0 && (
        <div className="related-products-section">
          <h2 className="related-products-title">Like that? You’ll Love This</h2>
          <div className="related-products-grid">
            {/* Passez un tableau de produits ici */}
            <Product
              product={relatedProducts}
              className="related-products-container"
            />
          </div>
        </div>
      )}

    </div>
  );
};

export default ProductDetailPage;
