import React from 'react';
import Product from '../../../Categories/Product';  // Corrected import statement
import './ProductList.css';
import { Product as ProductType } from '../../../../types/Products';

interface ProductListProps {
  products: ProductType[];  // Now using the default imported Product type directly
  productsPerSection: number;  // Ajouter cette ligne

}

const ProductList: React.FC<ProductListProps> = ({ products ,productsPerSection}) => {
  return (
    <div className="filtered-products-section">
      {products.length > 0 ? (
        <div className="products-container">
          <Product product={products} />
        </div>
      ) : (
        <p>Aucun produit trouvé pour cette catégorie.</p>
      )}
    </div>
  );
};

export default ProductList;
