import React, { useState } from 'react';
import { Button, IconButton, TextField, Typography, CircularProgress, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './Delivery.css';
import GoogleMapsSearchModal from './ModalMap';

const GoogleMapsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: 'currentColor', cursor: 'pointer' }}>
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z" />
    <circle cx="12" cy="9" r="2.5" />
  </svg>
);

export interface Location {
  latitude: string;
  longitude: string;
  address: string;
}

interface ModalWindowProps {
  isOpen: boolean;
  onClose: () => void;
  onLocationSelected?: (location: Location) => void;
}

const ModalWindow: React.FC<ModalWindowProps> = ({ isOpen, onClose, onLocationSelected }) => {
  const [selectedMethod, setSelectedMethod] = useState<'manual' | 'maps' | 'current'>('manual');
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isGoogleMapModalOpen, setIsGoogleMapModalOpen] = useState(false);

  const closeModal = () => {
    onClose();
    setIsGoogleMapModalOpen(false);
  };

  const handleMethodChange = (method: 'manual' | 'maps' | 'current') => {
    setSelectedMethod(method);
    if (method === 'maps') {
      setIsGoogleMapModalOpen(true);
    } else {
      setIsGoogleMapModalOpen(false);
    }
  };

  const handleGoogleMapsLocation = (location: { lat: number; lng: number; address: string }) => {
    setLatitude(location.lat.toString());
    setLongitude(location.lng.toString());
    setAddress(location.address);
    onLocationSelected?.({
      latitude: location.lat.toString(),
      longitude: location.lng.toString(),
      address: location.address
    });
    closeModal();  // This will close both modals
  };

  const fetchAddressFromCoordinates = async (latitude: string, longitude: string): Promise<string> => {
    const apiKey = 'AIzaSyBp3Fn1fzjsPGPZFJOfG7jntex0CWcyv3o'; // Remplacez par votre clé API
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK' && data.results.length > 0) {
        return data.results[0].formatted_address;
      } else {
        return 'Adresse non trouvée.';
      }
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'adresse', error);
      return 'Erreur lors de la récupération de l\'adresse';
    }
  };

  const handleManualSubmit = async () => {
    if (!latitude || !longitude) {
      setError('Veuillez remplir les deux coordonnées');
      return;
    }

    const lat = parseFloat(latitude);
    const long = parseFloat(longitude);

    if (isNaN(lat) || isNaN(long)) {
      setError('Les coordonnées doivent être des nombres valides');
      return;
    }

    if (lat < -90 || lat > 90 || long < -180 || long > 180) {
      setError('Coordonnées invalides. Latitude: -90 à 90, Longitude: -180 à 180');
      return;
    }

    setLoading(true);
    try {
      const address = await fetchAddressFromCoordinates(latitude, longitude);
      setAddress(address);
      onLocationSelected?.({
        latitude,
        longitude,
        address
      });
      closeModal();  // Assurez-vous que le modal se ferme ici
    } catch (error) {
      setError('Erreur lors de la récupération de l\'adresse');
    } finally {
      setLoading(false);
    }
  };

  const getCurrentLocation = async () => {
    if (!navigator.geolocation) {
      setError('La géolocalisation n\'est pas supportée par votre navigateur');
      return;
    }

    setLoading(true);

    try {
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });
      });

      const lat = position.coords.latitude.toString();
      const lng = position.coords.longitude.toString();

      setLatitude(lat);
      setLongitude(lng);

      const address = await fetchAddressFromCoordinates(lat, lng);
      setAddress(address);

      onLocationSelected?.({
        latitude: lat,
        longitude: lng,
        address
      });
      closeModal();  // Assurez-vous que le modal se ferme ici
    } catch (error) {
      setError('Impossible d\'obtenir votre position');
    } finally {
      setLoading(false);
    }
  };

 /* const handleGoogleMapsSelect = () => {
    // Implement logic to open Google Maps modal if needed
  };*/

  /*const handleCloseModal = () => {
    console.log("Closing modal...");
    setIsModalOpen(false);
  };*/

  return (
    <>
      {isOpen && (
        <div>
          <div className="custom-overlay" onClick={closeModal}></div>
          <div className="custom-modal">
            <div className="custom-modal-content">
              <div className="custom-modal-header">
                <div className="custom-modal-title-container">
                  <Typography variant="h6" className="custom-modal-title">
                    Sélectionnez votre position
                  </Typography>
                </div>
                <IconButton onClick={closeModal} className="custom-modal-close-button">
                  <CloseIcon />
                </IconButton>
              </div>

              <div className="custom-modal-body">
                <div className="method-selection">
                  <Button
                    variant={selectedMethod === 'manual' ? 'contained' : 'outlined'}
                    onClick={() => handleMethodChange('manual')}
                  >
                    Manuel
                  </Button>
                  <Button
                    variant={selectedMethod === 'maps' ? 'contained' : 'outlined'}
                    onClick={() => handleMethodChange('maps')}
                  >
                    Google Maps
                  </Button>
                  <Button
                    variant={selectedMethod === 'current' ? 'contained' : 'outlined'}
                    onClick={() => handleMethodChange('current')}
                  >
                    Position Actuelle
                  </Button>
                </div>
                <Typography variant="body1" className="custom-modal-subtitle">
                  {selectedMethod === 'manual' && 'Saisissez vos coordonnées'}
                  {selectedMethod === 'maps' && 'Sélectionnez un emplacement sur Google Maps'}
                  {selectedMethod === 'current' && 'Utilisez votre position actuelle'}
                </Typography>

                {error && (
                  <div className="error-message">
                    {error}
                  </div>
                )}

                {selectedMethod === 'manual' && (
                  <div className="custom-modal-form-group">
                    <TextField
                      label="Latitude"
                      variant="outlined"
                      fullWidth
                      className="custom-modal-input"
                      placeholder="ex: 48.8566"
                      value={latitude}
                      onChange={(e) => setLatitude(e.target.value)}
                      type="number"
                      error={!!error && !latitude}
                    />
                    <TextField
                      label="Longitude"
                      variant="outlined"
                      fullWidth
                      className="custom-modal-input"
                      placeholder="ex: 2.3522"
                      value={longitude}
                      onChange={(e) => setLongitude(e.target.value)}
                      type="number"
                      error={!!error && !longitude}
                    />
                    <Button
                      variant="contained"
                      fullWidth
                      className="custom-modal-location-button"
                      onClick={handleManualSubmit}
                      disabled={!latitude || !longitude}
                    >
                      Confirmer les coordonnées
                    </Button>
                  </div>
                )}

                {selectedMethod === 'maps' && (
                  <GoogleMapsSearchModal
                    isOpen={isGoogleMapModalOpen}
                    onClose={closeModal}
                    onLocationSelect={handleGoogleMapsLocation}
                  />
                )}

                {selectedMethod === 'current' && (
                  <Button
                    variant="contained"
                    fullWidth
                    className="custom-modal-location-button"
                    onClick={getCurrentLocation}
                    disabled={loading}
                    startIcon={loading ? <CircularProgress size={20} /> : <MyLocationIcon />}
                  >
                    {loading ? 'Chargement...' : 'Utiliser ma position actuelle'}
                  </Button>
                )}

                {address && (
                  <div className="custom-modal-address">
                    <LocationOnIcon className="address-icon" />
                    <Typography variant="body2">
                      {address}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalWindow;
